// Generates URLs for Events pages.
// Timeframe can either be a date in ISO-8601 format or a keyword like
// `this-weekend`
// If category or subcategory are omitted, the path part is omitted as well.
// All of these are examples of valid outputs of this function:
// /events/arts-culture/long-island/2020-05-16
// /events/arts-culture/2020-05-16
// /events/long-island/this-weekend
// /events/outdoors
// /events/nyc
// /events/2020-06-10
// /events/next-month
// /events
module.exports = (category, subcategory, timeframe, transit) => {
  return (
    '/' +
    ['events', category, subcategory, timeframe, transit]
      .filter(Boolean)
      .join('/')
  );
};
