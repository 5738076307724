import React from 'react';

export const MobileResults = ({
  num,
  section,
  timeframe,
  category,
  subcategory,
  transit,
}) => {
  const resultsFor = `${num} result${num === 1 ? '' : 's'} for "`;

  return (
    <p>
      {num > 0 &&
        (section !== 'articles' ? (
          <>
            {resultsFor}
            {timeframe || 'All dates'}", "{category || 'All categories'}", "
            {transit || 'All transit'}" and "{subcategory || 'All locations'}"
          </>
        ) : (
          `${num} article${num === 1 ? '' : 's'} found`
        ))}
    </p>
  );
};
