(() => {
  // utility to properly sets our date to midnight
  const setDateToMidnight = (dateISO) =>
    new Date(new Date(Date.parse(dateISO)).setHours(24, 0, 0, 0))

  const monthLabel = (dateISO) => {
    const months = 'January February March April May June July August September October November December'.split(' ')
    // const date = new Date(Date.parse(dateISO))
    const date = setDateToMidnight(dateISO)
    return `${months[date.getMonth()]} ${date.getFullYear()}`
  }

  // Returns a string so we can use it server-side and client-side alike
  // @param date The selected date
  // @param dates Dates for which we have events
  const renderCalendarToString = (focusDate, startDate, endDate, dates, category, subcategory) => {
    const days = 'Sun Mon Tue Wed Thu Fri Sat'.split(' ')
    // const parsedDate = new Date(Date.parse(focusDate))
    const parsedDate = setDateToMidnight(focusDate)
    const month = parsedDate.getMonth()
    const year = parsedDate.getFullYear()
    const firstDayOfMonth = new Date(year, month, 1)
    const firstDayOfCalendar = new Date(year, month, 1 - firstDayOfMonth.getDay())
    const items = days.map(day => `<div><b>${day}</b></div>`)
    let day = firstDayOfCalendar

    while (true) {
      const classes = []
      const isoDate = day.toISOString().split('T')[0]

      if (day.getMonth() !== month) {
        classes.push('other')
      }

      if (isoDate === new Date().toISOString().split('T')[0]) {
        classes.push('today')
      }

      if ((startDate && isoDate >= startDate) && (endDate && isoDate <= endDate)) {
        classes.push('selected')
      }

      if (dates.includes(day.toISOString().split('T')[0])) {
        classes.push('present')
      }

      const classString = classes.length ? ` class="${classes.join(' ')}"` : ''
      let content = day.getDate()
      if (dates.includes(isoDate)) {
        const path = `/${['events', category, subcategory, isoDate].filter(Boolean).join('/')}`
        content = `<a href="${path}">${content}</a>`
      }
      items.push(`<div${classString}>${content}</div>`)

      day = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1)

      if (day.getMonth() !== month && day.getDay() === 0) {
        break
      }
    }

    return items.join('')
  }

  const renderCalendar = () => {
    const calendar = document.querySelector('[data-calendar]')

    if (!calendar) {
      return
    }

    const focusDate = calendar.dataset.calendarFocusDate
    const startDate = calendar.dataset.calendarStartDate
    const endDate = calendar.dataset.calendarEndDate
    const dates = calendar.dataset.calendarDates.split(',')
    const category = calendar.dataset.calendarCategory
    const subcategory = calendar.dataset.calendarSubcategory
    const title = document.querySelector('[data-calendar-title]')

    if (title) {
      title.innerText = monthLabel(focusDate)
    }

    const datesContainer = document.querySelector('[data-calendar-body]')

    if (datesContainer) {
      datesContainer.dataset.calendarFocusDate = focusDate
      datesContainer.innerHTML = renderCalendarToString(focusDate, startDate, endDate, dates, category, subcategory)
    }

    const moveButtons = document.querySelectorAll('[data-calendar-move]')
    moveButtons.forEach((button) => {
      if (button.hasAttribute('data-calendar-bound')) {
        return
      }

      button.setAttribute('data-calendar-bound', 'true')

      button.addEventListener('click', () => {
        const move = parseInt(button.dataset.calendarMove, 10)
        const datesContainer = document.querySelector('[data-calendar-body]')
        if (!datesContainer) {
          return
        }
        const currentDate = datesContainer.dataset.calendarFocusDate
        const newFocusDate = new Date(Date.parse(currentDate))
        newFocusDate.setMonth(newFocusDate.getMonth() + move)
        const calendar = document.querySelector('[data-calendar]')
        if (!calendar) {
          return
        }
        calendar.dataset.calendarFocusDate = newFocusDate.toISOString().split('T')[0]
        renderCalendar()
      })
    })
  }

  if (typeof module !== 'undefined' && module.exports) {
    module.exports = { monthLabel, renderCalendar,  renderCalendarToString }
  } else {
    document.addEventListener('DOMContentLoaded', renderCalendar)
    document.addEventListener('turbolinks:load', renderCalendar)
  }
})()
