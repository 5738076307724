import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { Event } from '../components/event';
import { AdUnit } from '../components/adunit';
import { NoResults } from '../components/no-results';
import { MobileResults } from '../components/mobile-results';
import { monthLabel } from '../../static/render-calendar.js';
import TIMEFRAMES from '../timeframes';
import CATEGORIES from '../categories';
import LOCATIONS from '../locations';
import TRANSPORT from '../transport';
import fixAssetUrl from '../fix-asset-url';
import Filters from './filters';
import { Link } from 'gatsby';
import buildEventListPagePath from '../build-event-list-page-path';
import useMobile from '../hooks/use-mobile';
import {
  getMonthsOfOcurrence,
  datestringToMonth,
  isBeforeCurrentMonth,
} from '../utils/events';

export default ({ data, pageContext }) => {
  const {
    timeframe,
    startDate,
    category,
    subcategory,
    transit,
    categories,
    subcategories,
  } = pageContext;

  const fullevents = data.allContentfulEvent.edges.map(({ node }) => node);
  const { eventsPageAdUnit: adUnit } = data.contentfulHomePage;
  const buildPath = buildEventListPagePath;
  const hasArticles = false;

  const isMobile = useMobile();
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [events, setEvents] = useState(
    data.allContentfulEvent.edges.map(({ node }) => node)
  );
  const handleFilterToggle = () => {
    setFilterOpen(!filterOpen);
  };
  const [searchOpen, setSearchOpen] = useState(false);
  const handleSearchToggle = () => {
    setSearchOpen(!searchOpen);
  };

  let numResults = events.length;
  const resultsProps = {
    section: 'events',
    num: numResults,
    timeframe: TIMEFRAMES[timeframe]?.label,
    category: CATEGORIES[category]?.label,
    subcategory: LOCATIONS[subcategory]?.label,
    transit: TRANSPORT[transit]?.label,
  };

  useEffect(() => {
    filterOpen
      ? document.body.classList.add('hideScroll')
      : document.body.classList.remove('hideScroll');

    document.querySelector('header').classList.toggle('fixed', isMobile);
  }, [filterOpen, isMobile]);

  let title = 'Events';

  const isFilteredList =
    category || subcategory || timeframe || transit ? true : false;
  const eventsByMonth = [];
  const eventsByMonthToRender = [];
  if (isFilteredList === false) {
    events.forEach(event => {
      const { dateISO, endDateISO } = event;
      const months = getMonthsOfOcurrence(
        new Date(dateISO),
        new Date(endDateISO)
      );
      months.forEach(month => {
        if (eventsByMonth[month]) {
          eventsByMonth[month].push(event);
        } else {
          eventsByMonth[month] = [event];
        }
      });
    });

    for (let month in eventsByMonth) {
      // skip anything before the current month
      if (isBeforeCurrentMonth(month) === true) {
        continue;
      }

      // add the month heading
      const monthName = datestringToMonth(month);
      eventsByMonthToRender.push(
        <li key={monthName} className="article--events__month">
          {monthName}
        </li>
      );

      // render all events that occur in the month
      eventsByMonth[month].forEach(event => {
        eventsByMonthToRender.push(
          <Event key={`${month}-${event.id}`} event={event} />
        );
      });
    }
  }
  const pageSize = 10; // Number of events per page
  const totalPages = Math.ceil(events.length / pageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const eventsToRender = events.slice(startIndex, endIndex);

  const handleSearchInput = async event => {
    const searchString = event.target.value.toLowerCase();
    setSearchString(searchString);
    setEvents(
      fullevents.filter(result =>
        result.title.toLowerCase().includes(searchString)
      )
    );
  };
  if (TIMEFRAMES[timeframe]) {
    title = `${TIMEFRAMES[timeframe].label}’s events`;
  } else if (startDate) {
    title = `Events for ${monthLabel(startDate)}`;
  }

  let image = data.contentfulCategory.featuredImage?.file?.url;
  image = image ? fixAssetUrl(image) : null;

  const catCount = {
    'Arts + Culture': 0,
    'Eat + Drink +Shop': 0,
    Outdoors: 0,
  };
  const locCount = {
    Connecticut: 0,
    'Hudson Valley': 0,
    'Long Island': 0,
    NYC: 0,
  };
  const transitCount = {
    'Metro-North': 0,
    LIRR: 0,
    Subway: 0,
  };

  const categoryCounts =
    events.length > 0
      ? events.reduce((counts, event) => {
          const category = event.categories[0].title;
          catCount[category] = (catCount[category] || 0) + 1;
          return catCount;
        }, {})
      : {};

  const locationCounts =
    events.length > 0
      ? events.reduce((counts, event) => {
          const location = event.subcategories[0].title;
          locCount[location] = (locCount[location] || 0) + 1;
          return locCount;
        }, {})
      : {};

  const transportCounts =
    events.length > 0
      ? events.reduce((counts, event) => {
          const transport = event.transit?.routes[0].system;
          transitCount[transport] = (transitCount[transport] || 0) + 1;
          return transitCount;
        }, {})
      : {};
  const filterResults = { categoryCounts, locationCounts, transportCounts };

  return (
    <Layout
      title={title}
      mainClass="events"
      currentSection="events"
      image={image}
    >
      {isMobile && (
        <div
          id="searchButton__mobile"
          aria-label="Search"
          onKeyDown={handleSearchToggle}
          onClick={handleSearchToggle}
        >
          search
        </div>
      )}
      <div className={`section-header ${filterOpen ? 'removeShadow' : ''}`}>
        <div className="section-header-inner">
          <h1 className="section-title">Events</h1>
          {/* <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScu-TX1GOPB_4UUDNj_AZmhpUaP6ONd2xDhjljBVFchkS0aAg/viewform"
            rel="noreferrer"
            target="_blank"
            className="btn btn--inverted btn-submit-event"
          >
            Submit an Event{' '}
            <span className="material-icons md-18">open_in_new</span>
          </a> */}
        </div>

        {isMobile && !filterOpen && (
          <div className="section-filtering">
            <div className="mobile-results">
              {/* {timeframe || subcategory || category || transit ? (
                <MobileResults {...resultsProps} />
              ) : (
                <p>{`${numResults} events`}</p>
              )} */}
            </div>
            <button
              className="mobile-filter-btn"
              data-heading-filters
              onClick={() => handleFilterToggle()}
            >
              <span>Filters</span>
            </button>
          </div>
        )}
      </div>
      <div className="article-page">
        {isMobile && (
          <>
            <div
              className={`search mobile ${searchOpen ? '' : 'hide'}`}
              style={{ top: '-76px' }}
            >
              <div className="filter-header-inner__mobile">
                <h1>
                  <Link to="/">
                    <img
                      src="/logo.png"
                      alt="MTA Away"
                      id="mta-logo__mobile-search"
                    />
                  </Link>
                </h1>
                <div
                  id="closeButton__mobile"
                  aria-label="Close"
                  onKeyDown={handleSearchToggle}
                  onClick={handleSearchToggle}
                  data-heading-filters
                >
                  close
                </div>
                <div className="search__mobile-wrapper">
                  <p className="event-filters__label">Search</p>
                  <span className="material-icons icon-search__filter">
                    search
                  </span>
                  <input
                    className="event-filter__search"
                    placeholder="i.e: Montauk"
                    type="text"
                    onChange={handleSearchInput}
                  />
                </div>
              </div>
            </div>
            <div className={`filters mobile ${filterOpen ? '' : 'hide'}`}>
              <Filters
                {...{
                  category,
                  subcategory,
                  timeframe,
                  transit,
                  numResults,
                  buildPath,
                  hasArticles,
                  isMobile,
                  handleFilterToggle,
                }}
              />
            </div>
          </>
        )}
        <div className={`article ${adUnit ? '' : 'no-border'}`}>
          <ul className="article--events">
            {!events.length && (
              <NoResults
                message={`There are no events for ${
                  TIMEFRAMES[timeframe]?.label || 'All dates'
                }", "${CATEGORIES[category]?.label || 'All categories'}", "${
                  TRANSPORT[transit]?.label || 'All transit'
                }" and "${LOCATIONS[subcategory]?.label || 'All locations'}."`}
              />
            )}

            {eventsToRender.map(event => (
              <Event key={event.id} event={event} />
            ))}

            {totalPages < events.length && (
              <div className="pagination">
                <div>Continue exploring our events</div>
                <div className="pagination__page-numbers">
                  {Array.from(
                    { length: totalPages },
                    (_, index) => index + 1
                  ).map(page => (
                    <button
                      key={page}
                      className={`pagination-item ${
                        page === currentPage || page === 0 ? 'active' : ''
                      }`}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </ul>
          {/* <div className="submit-event">
            <div className="submit-event__content">
              <h2>Feature your next event</h2>
              <p>
                Get your next event featured along side the others on MTA Away
                and get more visibility into what you have upcoming.
              </p>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScu-TX1GOPB_4UUDNj_AZmhpUaP6ONd2xDhjljBVFchkS0aAg/viewform"
                rel="noreferrer"
                target="_blank"
                className="btn"
              >
                Submit an Event{' '}
                <span className="material-icons md-18">open_in_new</span>
              </a>
            </div>
            <img src="/submit-an-event.png" alt="" width="391" height="261" />
          </div> */}
        </div>
        <div className="sidebar">
          <div className="sidebar-inner">
            {!isMobile && (
              <>
                <p className="event-filters__label">Search</p>
                <span className="material-icons icon-search__filter">
                  search
                </span>
                <input
                  className="event-filter__search"
                  placeholder="i.e: Broadway"
                  type="text"
                  onChange={handleSearchInput}
                />
                <Filters
                  {...{
                    filterResults,
                    category,
                    subcategory,
                    timeframe,
                    transit,
                    numResults,
                    buildPath,
                    hasArticles,
                    isMobile,
                    handleFilterToggle,
                  }}
                />
              </>
            )}
            {adUnit && <AdUnit ad={adUnit} />}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query EventsQuery(
    $category: String
    $subcategory: String
    $startDate: Date!
    $endDate: Date!
    $transit: String
  ) {
    allContentfulEvent(
      filter: {
        categories: { elemMatch: { slug: { eq: $category } } }
        subcategories: { elemMatch: { slug: { eq: $subcategory } } }
        date: { lte: $endDate }
        endDate: { gte: $startDate }
        transit: { routes: { elemMatch: { system: { eq: $transit } } } }
      }
      sort: { fields: [date, title], order: ASC }
    ) {
      edges {
        node {
          ...EventListFragment
        }
      }
    }

    contentfulHomePage {
      eventsPageAdUnit {
        ...AdUnitFragment
      }
    }

    contentfulCategory(slug: { eq: "events" }) {
      featuredImage {
        file {
          url
        }
      }
    }
  }
`;
